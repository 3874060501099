<template>
    <dashboard-layout>
        <v-container>
            <v-row class="px-8 pt-2 align-center">
                <v-col cols="12">
                    <v-text-field
                        v-model="description"
                        hint="Description"
                        persistent-hint
                        append-icon="mdi-pencil"
                        class="text-h5"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        v-model="subject"
                        placeholder="Subject"
                        persistent-hint
                        append-icon="mdi-pencil"
                    />
                </v-col>
            </v-row>
            <v-row class="px-8">
                <v-col v-if="!loading" class="pl-2">
                    <div
                        id="editor"
                        v-html="html"
                        class="email-template"
                        ref="emailTemplate">
                    </div>
                </v-col>
                <v-col v-else>
                    <v-skeleton-loader type="list-item-avatar-three-line, image, article"/>
                </v-col>
            </v-row>
            <v-row class="px-8 py-10">
                <v-col>
                    <v-btn @click="handleSave" tile color="success">
                        <v-icon left>mdi-content-save-outline</v-icon>
                        Save
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </dashboard-layout>
</template>

<script>
import Quill from 'quill'
import EmailTemplate from "@/models/EmailTemplate";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "email-template-show",
    components: {DashboardLayout},
    data: function () {
        return {
            template: {},
            loading: true,
            editor: {},
            html: '',
            description: '',
            subject: null,
        }
    },
    methods: {
        async handleSave() {
            this.template.html = this.$refs.emailTemplate.children[0].innerHTML
            this.template.description = this.description
            this.template.subject = this.subject

            new EmailTemplate(this.template).save()
        },

        initQuill() {
            const options = {
                modules: {
                    // toolbar: true
                },
                placeholder: 'Compose an epic...',
                theme: 'snow'
            };
            this.editor = new Quill(this.$refs.emailTemplate, options);
        }
    },
    async mounted() {
        this.loading = true

        const templateId = this.$route.params.id
        this.template = await EmailTemplate.find(templateId)
        this.html = this.template.html;
        this.description = this.template.description;
        this.subject = this.template.subject

        this.loading = false

        this.$nextTick(this.initQuill)
    },
}
</script>
<style>
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";

.ql-editor p {
    margin-bottom: 0.5rem;
}
</style>
